/*        */
/* NAVBAR */
/*        */
.navbar {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  font-size: 20px;
  /* font-family: 'DM Sans'; */
  letter-spacing: 0.04rem;
  line-height: 1.1em;
  z-index: 12;
}
/*      */
/* LOGO */
/*      */
.logo {
  text-decoration: none;
}

.logo img {
  width: 150px;
  cursor: pointer;
  justify-self: start;
  margin-left: 10px;
  margin-top: 10px;
}

/*       */
/* LINKS */
/*       */
.nav-menu {
  display: grid;
  grid-template-columns:  repeat(5, auto);
  grid-gap: 10px;
  justify-content: end;
  list-style: none;
  text-align: center;
  width: 80%;
  margin-right: 2rem;
}

.nav-menu a{
  text-decoration: none;
  color: white;
  text-decoration: none;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  display: inline-block;
  position: relative;
  width: fit-content;}

.nav-menu a:hover {
  color: #051c23;
  border-radius: 4px;
  transition: all 0.2s ease;
}
/*             */
/* SOCIALMEDIA */
/*             */
.socialmedia {
  display: grid;
  grid-template-columns: repeat(2, auto);
  list-style: none;
  margin-left: 10rem;
}

/* hamburger icon */

#hamburger-icon {
  width: 40px;
  height: 30px;
  position: absolute;
  right: 15px;
  top: 25px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 12;
}

#hamburger-icon span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

/* hamburger icon - animatie */
#hamburger-icon span:nth-child(1) {
  top: 0px;
}

#hamburger-icon span:nth-child(2), #hamburger-icon span:nth-child(3) {
  top: 11px;
}

#hamburger-icon span:nth-child(4) {
  top: 21.5px;
}

#hamburger-icon.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#hamburger-icon.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#hamburger-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#hamburger-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#hamburger-icon.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

/* animation */
.nav-link:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  background: #051c23;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.nav-link:hover:after { 
  width: 100%; 
  left: 0; 
}

  /* hamburger-icon */
  #hamburger-icon span {
    display: none;
    background: white;
  }

/*            */
/* RESPONSIVE */
/*            */
@media screen and (max-width: 900px) {
  /* navbar */
  .navbar.active {
    position: fixed;
  }

  .navbar.active i, .navbar.active h1 {
    color: #051c23;
  }

  /* nav-menu */
  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding: 0;
    padding-top: 92.5vh;
    gap: 0;
  }

  .nav-menu.active {
    background-color: rgba(255, 255, 255, 1);
    left: 0;
    opacity: 1;
    transition: all 0.5 ease;
    z-index: 1;
  }

  /* links */
  .navbar a {
    color: #051c23;
    width: 100%;
    height: 50px;
    display: table;
    padding: 16px 0px;
    margin: 0;
    font-size: 28px;
  }

  /* sm */
  .socialmedia {
    padding: 0;
    width: 100%;
    margin: 0;
    margin-top: 50px;
  }

  /* logo */

  .navbar #logo, #logo img {
    height: 80px;
    width: 200px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
  }

  /* animation */
  .nav-link:after {
    display: none;
  }

  /* hamburger-icon */
  #hamburger-icon span {
    display: inherit;
  }

  #hamburger-icon.open span {
    background: #051c23;
  }

}

@media screen and (max-width: 640px) {
  .navbar #logo, #logo img {
    width: 150px;
    height: 40px;
    margin-top: 10px;
  }

  #hamburger-icon {
    width: 30px;
  }

  #hamburger-icon span {
    height: 4px;
  }

  
  #hamburger-icon span:nth-child(2), #hamburger-icon span:nth-child(3) {
    top: 9px;
  }
  
  #hamburger-icon span:nth-child(4) {
    top: 18px;
  }

}
