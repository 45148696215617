* {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100px;
  scroll-behavior: smooth;
}


.border {
  border: 10px solid white;
}

p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

a{
  transition: all 200ms ease;
}

/*           */
/* ANIMATION */
/*           */
.reveal{
  position: relative;
  transform: translateY(0px);
  opacity: 0;
  transition: 2s all ease;
}
.reveal.active{
  transform: translateY(0px);
  opacity: 1;
}
.reveal.late{
  position: relative;
  transform: translateY(0px);
  opacity: 0;
  transition: 1s all ease;
  transition-delay: 500ms;
  transition-property: opacity;
}
.reveal.late.active{
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0;
}
.reveal.left {
  opacity: 0;
  transition: 1s all ease;
  transform: translateX(-300px);
}
.reveal.left.active {
  transform: translateY(0);
  opacity: 1;
}
.revealBorder{
  position: relative;
  /* transform: translateY(0px);
  opacity: 1;
  transition: 1s all ease;
  transition-delay: 1.5s;
  transition-property: opacity; */
}


/* h1 style */
.title {
  color: #051c23;
  font-size: 100px;
  text-align: center;
  letter-spacing: 5px;
  margin: 0;
  text-align: left;
  font-weight: 900;
  margin-bottom: 1.2rem;
  
  --x-offset: -0.0625em;
  --y-offset: 0.0625em;
  --stroke: 0.025em;
  --background-color: white;
  --stroke-color: lightblue;
  text-shadow: 
    
  var(--x-offset)
  var(--y-offset)
  0px
  var(--background-color), 
  
  calc( var(--x-offset) - var(--stroke) )
  calc( var(--y-offset) + var(--stroke) )
  0px
  var(--stroke-color);
}
.title.projectTitle {
  color: white;
  --background-color: #051c23;
}

@supports ( text-shadow: 1px 1px 1px 1px black ) {
  .title {
    text-shadow:
      
      var(--x-offset)
      var(--y-offset)
      0px
      0px
      var(--background-color), 
      
      var(--x-offset) 
      var(--y-offset)
      var(--stroke)
      0px
      var(--stroke-color);
    
  }
}

@media screen and (max-width: 1080px) {
    /* border */
    .border{
      border: none;
    }
  
}
