.popup {  
  display: none;
  position: fixed;  
  width: 100%;  
  height: 100vh;  
  top: 0;  
  left: 0;  
  right: 0;  
  bottom: 0;  
  margin: auto;  
  background-color: rgba(0,0,0, 0.5);  
  z-index: 20;
}

.popup h1 {
  color: #051c23;
  margin-bottom: 24px;
  font-size: 40px;
  text-align: center;
  letter-spacing: 5px;
}

.popup img {
  width: 200px;
}

.popup input {
  margin: 0;
}

.popup button {
  position: absolute;
  left: -6px;
  top: -6px;
  padding: 15px;
  background-color: #051c23;
  color: white;
  border: 2px solid transparent;
  cursor: pointer;
}

.popup button:hover {
  background-color: white;
  color: #051c23;
  border: 2px solid #051c23;
  transition: all 300ms ease;
}

.popup_open {  
  position: absolute;  
  left: 35%;  
  right: 35%;  
  top: 5%;  
  bottom: 5%;  
  margin: auto;  
  background: white;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


/* ipad format */
@media screen and (max-width: 900px) {
  .popup_open {
    left: 25%;  
    right: 25%;  
    top: 15%;  
    bottom: 15%; 
  }
}

/* smartphone format */
@media screen and (max-width: 640px) {

  .popup_open {
    left: 15%;  
    right: 15%;  
    top: 10%;  
    bottom: 10%; 
  }

  .popup img {
    width: 150px;
  }

  .popup input, .popup textarea {
    padding: 10px;
    font-size: 12px;
  }

  .popup textarea {
    height: 50px;
  }

  .popup button {
    padding: 10px;
    top: -10px;
    left: -10px;
  }

  .popup h1 {
    font-size: 24px;
  }

  .popup #submit {
    padding: 10px 0;
  }

}

@media screen and (max-width: 400px) {

  /* .popup_open {
    top: 0;
    bottom: 0;
  } */

  .popup_open {
    height: fit-content;
  }

  .popup_open form {
    padding-bottom: 10px;
  }

  .popup img {
    width: 80px;
  }


  .popup h1 {
    padding-top: 10px;
  }

  #message {
    font-size: 16px;
    padding: 0;
  }
}