/*      */
/* HOME */
/*      */

/* BANNER */
.home {
  width: 100%;
}

#banner,
#gallery .banner {
  background: url("./assets/bannerHome.jpg") center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "";
  opacity: 1;
  position: relative;
  z-index: -1;
  overflow: hidden;
  width: 100%;
  height: 98vh;
  transition: opacity 300ms ease;
}

#banner div,
#gallery .banner div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#banner > *,
#gallery .banner > * {
  opacity: 1;
  transition: opacity 300ms ease;
}

#banner h1,
#gallery .banner h1 {
  color: white;
  font-family: "Zen Old Mincho", serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 32px;
  padding: 10px;
}

.banner-innerContainer div {
  border-top: 2px solid white;
  border-bottom: 1px solid white;
  max-height: 1px;
  width: 25%;
}

#banner img {
  position: relative;
  width: 80px;
}

#banner p,
#banner svg,
#gallery .banner svg,
#gallery .banner p {
  color: white;
  font-weight: 500;
}

#banner p,
#gallery .banner p {
  margin-top: 20px;
  font-size: 12px;
}

/* ABOUT */
#about {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
}

#about img,
#contact img {
  width: 400px;
  margin: 20px;
  margin-bottom: 0;
  box-shadow: 0 0 5px 0px rgba(4, 4, 4, 0.597);
}

#about .title {
  position: relative;
  left: -110px;
  margin-bottom: 60px;
}

#about p,
#about a {
  width: 400px;
  line-height: 2rem;
  letter-spacing: 0.3px;
  word-spacing: 2px;
}

#about p {
  color: rgb(96, 96, 96);
}

#about a {
  color: #051c23;
  font-weight: 600;
  display: flex;
  align-items: center;
}

#about a:hover {
  color: rgb(94, 139, 25);
}

/* categories */
#categories div {
  display: flex;
  align-items: center;
  width: 100%;
  background-size: cover;
  color: white;
  text-align: center;
  font-size: 24px;
}

#categories ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  list-style: none;
  width: 100%;
  height: 80vh;
}

#categories a {
  text-decoration: none;
  color: white;
}

#categories li {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

#categories li:hover {
  background-size: cover;
}

/*
#category-nature {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.964)),
    url("./assets/categoryNatureHome.jpg");
}
#category-wildlife {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.964)),
    url("./assets/categoryWildlifeHome.JPG");
}
#category-portrait {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.964)),
    url("./assets/categoryPortraitHome.jpg");
}
#category-macro {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.964)),
    url("./assets/categoryMacroHome.jpg");
}

#category-nature:hover {
  background-image: linear-gradient(
      rgba(242, 247, 243, 0.096),
      rgba(20, 20, 20, 0.801)
    ),
    url("./assets/categoryNatureHome.jpg");
}
#category-wildlife:hover {
  background-image: linear-gradient(
      rgba(242, 247, 243, 0.096),
      rgba(20, 20, 20, 0.801)
    ),
    url("./assets/categoryWildlifeHome.JPG");
}
#category-citiesandstreets {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.964)),
    url("./assets/categoryCitiesAndStreetsHome.JPG");
}
#category-portrait:hover {
  background-image: linear-gradient(
      rgba(242, 247, 243, 0.096),
      rgba(20, 20, 20, 0.801)
    ),
    url("./assets/categoryPortraitHome.jpg");
}
#category-macro:hover {
  background-image: linear-gradient(
      rgba(242, 247, 243, 0.096),
      rgba(20, 20, 20, 0.801)
    ),
    url("./assets/categoryMacroHome.jpg");
}
#category-citiesandstreets:hover {
  background-image: linear-gradient(
      rgba(242, 247, 243, 0.096),
      rgba(20, 20, 20, 0.801)
    ),
    url("./assets/categoryCitiesAndStreetsHome.JPG");
} */

/* PROJECTS */
#projects {
  padding: 120px 0;
  width: 100%;
  height: 100%;
  display: grid;
  justify-items: center;
  background-color: #051c23;
  font-family: "Raleway", sans-serif;
}

#projects div {
  display: flex;
}

#projects p,
#projects h2 {
  width: 80%;
}

#projects h2 {
  margin-bottom: 10px;
}

#projects span {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}

.project {
  height: 400px;
  flex-direction: row;
  margin-top: 72px;
  /* width: auto; */
  display: flex;
  justify-content: center;
  /* box-shadow: 0px 0px 10px 4px black; */
}

.project a {
  color: #051c23;
  font-weight: 600;
}

.project span {
  font-size: 16px;
  margin-top: 10px;
}

.project a:hover, .project span:hover {
  color: rgb(94, 139, 25);
  transition: all 200ms ease;
}

.project div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.project div div {
  background-color: white;
  /* max-height: 300px; */
  max-width: 1200px;
}

/* popup project */

#projects .popup-project {
  display: none;
  position: fixed; /* Stay in place */
  z-index: 15; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

#projects .images {
  padding-bottom: 100px;
}

#projects .close {
  color: white;
  text-decoration: none;
}

#projects .popup-project div {
  display: block;
}

#projects .popup-project.active {
  display: flex;
  align-items: center;
}

#projects .sm-image-category {
  border: 2px solid rgba(255, 255, 255, 0);
}

#projects .sm-image-category img:first-child:hover {
  cursor: auto;
  opacity: 1;
}

/*         */
/* GALLERY */
/*         */

/* banner */
#gallery .banner {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    url("./assets/gallery/bannerGallery.jpg") center;
  background-size: cover;
  opacity: 1;
}

#gallery .banner-innerContainer {
  opacity: 1;
}

#gallery .banner h1 {
  font-size: 40px;
}

/* categories */
.gallery-category {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 98vh;
}

.gallery-category div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.gallery-innerContainer {
  width: 25%;
}

.gallery-category p,
.gallery-category h1 {
  width: auto;
  max-width: 600px;
  color: #051c23c2;
}


.gallery-category h1 {
  font-family: "MD Sans";
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 42px;
  padding: 10px;
}

.gallery-category button {
  padding: 10px 20px;
  margin-top: 50px;
  cursor: pointer;
  border: 1px solid #051c23c2;
}

.gallery-category button:hover {
  color: rgba(255, 255, 255, 0.938);
  border-color: rgba(255, 255, 255, 0);
  background-color: #051c23c2;
  transition: border-color, background-color 300ms ease;
}

.gallery-innerContainer {
  margin-right: 10%;
  padding: 50px;
  height: 400px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.938);
}

/* CATEGORY */
.categoryBanner {
  min-height: 70vh;
}

.category h1 {
  width: 100%;
  font-size: 60px;
  color: #051c23cf;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
}

/* category pictures */
.gallery-nature {
  background: url("./assets/gallery/bannerGalleryNature.jpg") center;
  background-size: cover;
}
.gallery-wildlife {
  background: url("./assets/gallery/bannerGalleryWildlife.jpg") center;
  background-size: cover;
}
.gallery-macro {
  background: url("./assets/gallery/bannerGalleryMacro.jpg") center;
  background-size: cover;
}
.gallery-portrait {
  background: url("./assets/gallery/bannerGalleryPortrait.jpg") center;
  background-size: cover;
}
.gallery-citiesandstreets {
  background: url("./assets/gallery/bannerGalleryCitiesAndStreets.JPG") center;
  background-size: cover;
}

/* GRID */
.sm-image-category {
  border: 2px solid white;
}

.sm-image-category img:first-child:hover {
  cursor: pointer;
  opacity: 0.4;
}

/* ICONS */
.image-icons svg {
  cursor: pointer;
  font-size: 30px;
}

.image-icons div {
  position: absolute;
  bottom: 50%;
  left: 46%;
  right: 50%;
  top: 45%;
  z-index: -1;
}

.img-wrapper {
  position: relative;
}

/* GRID PHOTOS */
.images {
  margin: 0 200px;
  line-height: 0;

  -webkit-column-count: 5;
  -webkit-column-gap: 0px;
  -moz-column-count: 5;
  -moz-column-gap: 0px;
  column-count: 5;
  column-gap: 0px;
}

#projects .images {
  line-height: 0;

  -webkit-column-count: 3;
  -webkit-column-gap: 0px;
  -moz-column-count: 3;
  -moz-column-gap: 0px;
  column-count: 3;
  column-gap: 0px;
}

#projects .popup-project.active {
  align-items: flex-start;
}

.images img {
  width: 100% !important;
  height: auto !important;
}

/* ON IMAGE CLICK */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 15; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content,
#caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 35px;
  right: 35px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #051c23;
  text-decoration: none;
  cursor: pointer;
}

/*           */
/* BIOGRAPHY */
/*           */
#biography .banner {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.964)),
    url("./assets/about/bannerAbout.JPG") top right;
  opacity: 1;
  min-height: 97vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  width: 100%;
  color: white;
}

#biography div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#biography #upper-image img, #bottom-image img {
  width: 40%;
  -webkit-box-shadow: -2px -1px 15px 7px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -3px -2px 30px 14px rgba(0, 0, 0, 0.425);
  box-shadow: -4px -3px 45px 21px rgba(0, 0, 0, 0.35);
}

#biography h1 {
  font-size: 50px;
  margin-bottom: 10px;
  padding-top: 15%;
}

#biography p, #info {
  width: 80%;
  font-size: 14px;
  line-height: 1.5rem;
  letter-spacing: 0.2px;
  word-spacing: 1.5px;
  text-align: justify;
}

#biography #upper-image {
  display: none;
}

#info {
  font-size: 12px;
  padding-top: 15px;
  padding-bottom: 40px;
  display: flex;
  text-align: center;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#biography .uitrusting {
  margin-top: 20px;
  width: 80%;
}

#biography .uitrustingItem {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.uitrusting img {
  width: 25px;
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
}

/*      */
/* SHOP */
/*      */

#shop {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  font-family: sans-serif;
  background-color: #F8F7F3;
}

#shop h1 {
  font-size: 56px;
  margin-bottom: 10%;
  color: #051c23;
  letter-spacing: 5px;
  text-transform: uppercase;
}

#shop p {
  font-size: 20px;
  line-height: 24px;
}

#shop a {
  color: #051c23;
  font-weight: 600;
  font-size: 24px;
}

#shop a:hover {
  color: rgb(104, 154, 29);
  transition: all 500ms ease;
}

.werkaandemuur {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.werkaandemuur div {
  width: 80%;
}

.articles {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 40%;
}

.article {
  margin: 10px;
}

.article img {
  width: 100%;
}

/* CONTACT */
/*         */
#contact {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  min-height: 92vh;
}

form {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
}

#contact div {
  margin-top: 100px;
}

#contact div:first-child {
  justify-self: center;
}

#contact div:nth-child(2) {
  margin-left: 40px;
}

form input,
textarea {
  margin-bottom: 2rem;
  border: none;
  border-bottom: 1px solid black;
  padding: 20px;
  padding-left: 5px;
  font-size: 16px;
}

textarea {
  height: 100px;
  resize: none;
}

#submit {
  padding: 15px;
  border: 1px solid white;
  color: white;
  background-color: #051c23;
  text-align: center;
  cursor: pointer;
}

#submit:hover {
  border: 1px solid #051c23;
  background-color: white;
  color: #051c23;
  transition: all 0.3s ease;
}

#message {
  font-size: 18px;
  color: white;
  margin: 0;
  height: fit-content;
  padding-top: 10px;
}

#contact h1 {
  color: #051c23;
  font-size: 70px;
  letter-spacing: 5px;
  text-transform: uppercase;
  margin: 0;
}

/*            */
/* RESPONSIVE */
/*            */

/* images */
@media (max-width: 1200px) {
  .images {
    margin: 0;
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}
@media (max-width: 1000px) {
  .images {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}
@media (max-width: 800px) {
  .images {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}
@media (max-width: 400px) {
  .images {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}

/* ipad format */
@media screen and (max-width: 900px) {
  /* banner */
  .banner {
    border: none;
    min-height: 100vh;
  }

  /* home */
  #home h1 {
    width: fit-content;
  }

  #contact img,
  #about img {
    width: 300px;
  }

  /* about */
  #about,
  #home #contact {
    min-height: 60vh;
  }

  /* contact */
  #contact div {
    margin: 0;
  }

  #contact div:nth-child(2) {
    margin-left: 0;
  }

  #contact form {
    width: 90%;
  }

  /* biography */
  #biography .banner {
    grid-template-columns: 1fr;
    padding-bottom: 40px;
  }

  #biography #bottom-image {
    display: none;
  }

  #biography #upper-image {
    display: flex;
    margin-top: 120px;
  }

  #biography h1 {
    padding: 20px;
  }

  /* projects */
  .popup-project {
    padding-top: 100px;
    overflow: auto;
    flex-direction: column;
  }

  .popup-project .images {
    flex-direction: column;
  }

  /* shop */
  #shop {
    flex-direction: column;
    text-align: center;
    height: 94vh;
  }

  #shop h1 {
    margin-top: 10%;
    margin-bottom: 5%;
  }

  .articles {
    width: 80%;
  }

  /* projects */
  #projects p {
    font-size: 14px;
  }
}

/* smartphone format */
@media screen and (max-width: 640px) {

  /* home */
  #banner,
  #gallery .banner {
    background: url("./assets/bannerHome_mobile.jpg") center;
  }
  #home #banner-innerContainer h1 {
    font-size: large;
    width: 100%;
  }

  #home #banner-innerContainer img {
    width: 60px;
  }

  #home h1,
  #home .title,
  #contact h1 {
    font-size: 50px;
    width: 100%;
    margin: 20px 0 10px 0;
    text-align: center;
  }

  #home #about,
  #home #contact {
    margin: 50px 0;
  }

  #contact img,
  #about img {
    width: 80%;
  }

  /* about */
  #about {
    flex-direction: column;
  }

  #about .title {
    left: 0;
  }

  #about div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #about p,
  #about a {
    width: 80%;
  }

  /* contact */
  #contact {
    grid-template-columns: 1fr;
    width: 100%;
  }

  #contact img {
    margin-top: 150px;
  }

  #home #contact img {
    margin-top: 0;
  }

  #contact div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  /* projects */
  #projects div {
    flex-direction: column;
  }

  #projects img {
    width: 200px;
  }

  .project {
    align-items: center;
    height: 100%;
    text-align: justify;
  }

  #projects h2,
  #projects span {
    text-align: center;
    justify-content: center;
  }

  /* gallery */
  .gallery-innerContainer {
    margin-right: 0;
    padding: 10px;
    width: 80%;
    height: 320px;
  }

  .gallery-category {
    justify-content: center;
  }

  .gallery-innerContainer h1 {
    text-align: center;
    font-size: 24px;
    width: 100%;
  }

  .gallery-innerContainer p {
    width: 100%;
    text-align: justify;
  }

  .category h1 {
    font-size: 35px;
  }

  #gallery .banner {
    background: url("./assets/gallery/bannerGallery_mobile.jpg") center;
  }
  /* category pictures */
  .gallery-nature {
    background: url("./assets/gallery/bannerGalleryNature_mobile.jpg") center;
    background-size: cover;
  }
  .gallery-wildlife {
    background: url("./assets/gallery/bannerGalleryWildlife_mobile.jpg") center;
    background-size: cover;
  }
  .gallery-macro {
    background: url("./assets/gallery/bannerGalleryMacro_mobile.jpg") center;
    background-size: cover;
  }
  .gallery-portrait {
    background: url("./assets/gallery/bannerGalleryPortrait_mobile.jpg") center;
    background-size: cover;
  }
  .gallery-citiesandstreets {
    background: url("./assets/gallery/bannerGalleryCitiesAndStreets_mobile.JPG") center;
    background-size: cover;
  }

  /* shop */
  #shop h1 {
    font-size: 24px;
  }

  #shop p {
    font-size: 16px;
  }

  #shop a {
    font-size: 16px;
  }
}

/* small devices */
@media screen and (max-width: 400px) {
  #home h1,
  #home .title,
  #contact h1 {
    font-size: 40px;
  }

  #home #banner-innerContainer h1 {
    font-size: small;
  }

  /* gallery */
  #gallery .banner-innerContainer h1 {
    font-size: large;
  }

  .gallery-innerContainer {
    height: 280px;
  }

  .gallery-innerContainer h1 {
    font-size: 20px;
  }

  .gallery-innerContainer p {
    font-size: 14px;
  }

  .gallery-innerContainer button {
    padding: 10px;
    margin-top: 20px;
  }
}
