.footer {
  position: relative;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  z-index: 12;
}

.footer p {
  color: #051c23;
}

@media screen and (max-width: 1080px) {
  .footer {
    font-size: 9.2px;
  }
}
